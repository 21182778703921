import { useMutation } from 'react-query';

import { useWeb3Context } from 'src/context/web3';
import { notify } from 'src/utils/notify';

import useAuthReducer from './reducers/useAuthReducer';
import useAPICaller from './useAPICaller';

export const useLogout = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		() =>
			fetchAPI({
				endpoint: '/authentication/logout',
				method: 'POST',
			}),
		{
			onSuccess: () => {},
			// onError: (err: any) => {
			// notify(err?.message, 'error');
			// },
		},
	);
};

export const useRegisterEmail = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: {
			email: string;
			username: string;
			password: string;
			password_confirmation: string;
		}) =>
			fetchAPI({
				endpoint: '/authentication/register',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useResendRegisterOtp = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { email: string }) =>
			fetchAPI({
				endpoint: '/authentication/resend-otp',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useVerifyRegisterOtp = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { email: string; otp: string }) =>
			fetchAPI({
				endpoint: '/authentication/verify-otp',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useLoginEmail = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { email: string; password: string }) =>
			fetchAPI({
				endpoint: '/authentication/email/login',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useLoginWallet = () => {
	const { fetchAPI } = useAPICaller();

	const { disconnect } = useWeb3Context();
	const { clearUser } = useAuthReducer();

	return useMutation(
		(payload: { wallet_id: string }) =>
			fetchAPI({
				endpoint: '/authentication/wallet/login',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: async (err: any) => {
				clearUser();
				disconnect();

				notify(
					'Login with wallet failed. ' +
						(err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message),
					'error',
				);
			},
		},
	);
};

export const useSyncNfts = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(() => fetchAPI({ endpoint: '/sync-nfts', method: 'POST' }), {
		onSuccess: () => {},
		onError: (err: any) => {
			notify(err?.message || 'Failed to sync nfts', 'error');
		},
	});
};

export const useLoginSns = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { sns_id: string; sns_type: string; email: string }) =>
			fetchAPI({
				endpoint: '/authentication/sns/login',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useRegisterWhatsapp = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { phone_number: string }) =>
			fetchAPI({
				endpoint: '/authentication/register/phone-number',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useVerifyOtpWhatsapp = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { verify_token: string; creo_id: string; otp: string }) =>
			fetchAPI({
				endpoint: '/authentication/verify-otp/phone-number',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useResendOtpWhatsapp = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { verify_token: string; creo_id: string }) =>
			fetchAPI({
				endpoint: '/authentication/resend-otp/phone-number',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useLoginWhatsapp = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { phone_number: string }) =>
			fetchAPI({
				endpoint: '/authentication/phone-number/login',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useRecoverAccountXellar = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { access_token: string; creo_id: string; secret0: string }) =>
			fetchAPI({
				endpoint: '/authentication/xellar/recover',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useVerifyOtpXellar = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { verify_token: string; creo_id: string; otp: string }) =>
			fetchAPI({
				endpoint: '/authentication/xellar/otp',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useRegisterTelegram = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { phone_number: string }) =>
			fetchAPI({
				endpoint: '/authentication/register/phone-number',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useLoginTelegram = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { phone_number: string }) =>
			fetchAPI({
				endpoint: '/authentication/phone-number/login',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useGetTokenXellar = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { phone_number: string }) =>
			fetchAPI({
				endpoint: '/authentication/xellar/token',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useGetProfileData = () => {
	const { fetchAPI } = useAPICaller();

	const { disconnect } = useWeb3Context();
	const { clearUser } = useAuthReducer();
	const { mutate: logout } = useLogout();

	const handleDisconnect = async () => {
		logout();
		clearUser();
		disconnect();
		window.location.reload();
	};

	return useMutation(
		() =>
			fetchAPI({
				endpoint: '/profile',
			}),
		{
			onSuccess: () => {},
			onError: (/*err: any*/) => {
				// notify(
				// err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
				// 'error',
				// );
				// error wallet auth
				notify('Failed to get profile data. Please try to re-login.', 'error');
				handleDisconnect();
			},
		},
	);
};

export const useLinkSns = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { sns_type: string; sns_id: string; email: string }) =>
			fetchAPI({
				endpoint: '/authentication/sns/link',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useUnlinkSns = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { sns_type: string }) =>
			fetchAPI({
				endpoint: '/authentication/sns/unlink',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useRequestResetPassword = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { email: string }) =>
			fetchAPI({
				endpoint: '/authentication/forgot-password',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useVerifyResetPassword = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { forgot_password_token: string; password: string; password_confirmation: string }) =>
			fetchAPI({
				endpoint: '/authentication/forgot-password/verify',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useSubscribeNewsletter = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { email: string }) =>
			fetchAPI({
				endpoint: '/subscribe/newsletter',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useGetLoginKey = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		() =>
			fetchAPI({
				endpoint: '/authentication/login-key',
				method: 'POST',
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useCheckUsername = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { username: string }) =>
			fetchAPI({
				endpoint: '/check-username',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useUpdateUsername = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { username: string }) =>
			fetchAPI({
				endpoint: '/authentication/username',
				method: 'PUT',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useUpdateAvatar = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { image: string }) =>
			fetchAPI({
				endpoint: '/upload-profile-picture',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useAddEmailAndPassword = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { email: string; password: string; password_confirmation: string }) =>
			fetchAPI({
				endpoint: '/authentication/modify-email-password',
				method: 'PUT',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useUpdatePassword = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { current_password: string; password: string; password_confirmation: string }) =>
			fetchAPI({
				endpoint: '/authentication/password',
				method: 'PUT',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};
